<!--
  兑换管理
-->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="兑换订单列表"
        operateFixedType="right"
        :selectable="false"
        :selectOnIndeterminate="false"
        :data="tableData"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              type="plain"
              v-rbac="'marketing:integral:cash:export'"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-operation-dialog
      title="发货处理"
      :on-close="onCloseIncrease"
      :show.sync="showAddDialog"
      :before-cancel="beforeCancelDeliver"
      :before-confirm="beforeConfirmDeliver">
      <div slot="content">
        <ykc-form :model="deliverForm" :rules="deliverRules" ref="deliverForm">
          <ykc-form-item label="物流单号" prop="logisticsOrderNo">
            <ykc-input
              placeholder="输入物流单号"
              v-model="deliverForm.logisticsOrderNo"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';

  import regexpObj from '@/utils/regexp';
  import { pointsMall } from '@/service/apis';
  import { code, getLastTime, offlineExport } from '@/utils';

  export default {
    name: 'exchangeList',
    data() {
      return {
        dictionary: {
          skuType: [
            { id: '1', name: '车辆用品' },
            { id: '2', name: '服饰' },
            { id: '3', name: '文具' },
            { id: '4', name: '纪念品' },
            { id: '5', name: '其他' },
          ],
          orderStatus: [
            { id: '0', name: '待发货' },
            { id: '1', name: '已发货' },
            { id: '3', name: '已取消' },
          ],
        },
        tableData: [],
        tableColumns: [
          { label: '订单号', prop: 'orderCode', minWidth: '200px' },
          { label: '商品编号', prop: 'skuNo', minWidth: '200px' },
          { label: '商品名称', prop: 'skuName', minWidth: '240px' },
          { label: '商品类别', prop: 'skuTypeMean', minWidth: '130px' },
          { label: '兑换数量', prop: 'skuNum', minWidth: '130px' },
          { label: '支付积分', prop: 'allSkuScore', minWidth: '130px' },
          { label: '订单状态', prop: 'orderStatusMean', minWidth: '100px' },
          { label: '兑换人', prop: 'exchangePerson', minWidth: '130px' },
          { label: '兑换时间', prop: 'createdTime', minWidth: '150px' },
        ],

        tableOperateButtons: [
          {
            enabled: () => code('marketing:integral:cash:send'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.orderStatus !== '0'} // 订单状态不是待发货，就置灰
                onClick={() => {
                  this.doDeliver(row);
                }}>
                发货
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:cash:cancel'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.orderStatus !== '0'} // 订单状态 不是待发货，就置灰
                onClick={() => {
                  YkcDialog({
                    showTitle: false,
                    showFooter: true,
                    dialogType: 'feedback',
                    desc: '确定取消订单？',
                    onCancel: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                    },
                    onConfirm: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                      this.doCance(row);
                      this.showDrawer = false; // 隐藏抽屉
                    },
                  });
                }}>
                取消
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:cash:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/marketingCenter/platformMarketingTools/pointsMall/exchange/details',
                query: {
                  id: row.id,
                },
              });
            },
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        searchParams: {
          orderCode: '',
          skuName: '',
          skuNo: '',
          skuType: '',
          orderStatus: '',
          userAccount: '',
          orderTime: [],
        },
        showAddDialog: false, // 发货处理弹窗
        deliverForm: {
          logisticsOrderNo: '',
        },
        deliverRules: {
          logisticsOrderNo: [
            { required: true, message: '请输入物流单号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('物流单号'),
            },
          ],
        },
      };
    },
    created() {
      // const startDate = getLastTime('yyyy-MM-dd', 7);
      // const endDate = getLastTime('yyyy-MM-dd', 0);
      // this.searchParams.orderTime = [startDate, endDate];

      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'orderTime',
            label: '下单时间',
          },
          {
            comName: 'YkcInput',
            key: 'orderCode',
            label: '订单号',
            placeholder: '请输入订单号',
          },
          {
            comName: 'YkcInput',
            key: 'exchangePerson',
            label: '兑换账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'skuName',
            label: '商品名称',
            placeholder: '请输入商品名称',
          },
          {
            comName: 'YkcInput',
            key: 'skuNo',
            label: '商品编号',
            placeholder: '请输入商品编号',
          },
          {
            comName: 'YkcDropdown',
            key: 'skuType',
            label: '商品类别',
            data: this.dictionary.skuType,
          },
          {
            comName: 'YkcDropdown',
            key: 'orderStatus',
            label: '订单状态',
            data: this.dictionary.orderStatus,
          },
        ];
      },
    },
    methods: {
      /* 发货  取消 */
      beforeCancelDeliver(done) {
        this.$refs.deliverForm.clearValidate();
        done();
      },
      /* 发货  确定 */
      beforeConfirmDeliver(row) {
        console.log('row', row);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            const reqParams = {
              ...this.deliverForm,
            };
            console.log(reqParams);
            pointsMall.deliver(reqParams).then(() => {
              this.showAddDialog = false;
              this.searchTableList();
              this.$message({
                message: '发货成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.deliverForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /* 发货按钮 */
      doDeliver(row) {
        console.log('row', row);
        this.showAddDialog = true;
        this.deliverForm.id = row.id;
        this.deliverForm.orderCode = row.orderCode;
      },
      /* 取消按钮 */
      doCance(row) {
        pointsMall.cancel({ id: row.id }).then(res => {
          console.log('res', res);
          this.$message({
            message: '取消成功',
            type: 'success',
          });
          this.searchTableList();
          console.log(row.orderStatus);
        });
      },
      /* 发货弹窗 关闭 */
      onCloseIncrease() {
        this.$refs.deliverForm.clearValidate();
        this.showAddDialog = false;
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          pointsMall.page({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 兑换列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'mall_order_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        const startDate = getLastTime('yyyy-MM-dd', 7);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.orderTime = [startDate, endDate];
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
